<template>
    <b-form class='basic-form' novalidate>
        <h2>Do you want to delete</h2>
        <div>
            <b-form-checkbox v-model="deletePayments" class="mb-2" :value="1" unchecked-value="0">
                Payments
            </b-form-checkbox>
            <b-form-checkbox v-model="deleteDelivery" class="mb-2" :value="1" unchecked-value="0">
                Delivery
            </b-form-checkbox>
        </div>

        <button v-if='!this.deleting' @click="deleteEntitySaleable()" class="btn btn-danger">Delete</button>

        <b-button v-if='this.deleting' variant="danger" :disabled='true'>
            <b-spinner small type="grow"></b-spinner>
            deleting...
        </b-button>
    </b-form>
</template>
<script>
export default {
    props: ['id', 'entity_id'],
    data: function () {
        return {
            deletePayments: 0,
            deleteDelivery: 0,
            deleting: false
        }
    },
    methods: {
        deleteEntitySaleable: function () {
            if (this.id && this.entity_id) {
                this.deleting = true;
                let confirmations = {
                    delete_payments: this.deletePayments,
                    delete_delivery: this.deleteDelivery,
                };
                return this.apiRequest(apiClient => apiClient.delete('entity-saleables/' + this.id, confirmations)).then(
                    () => {
                        this.deleting = false;
                        this.$emit('saved', 1);
                        this.modal('cashReturnForm', { entityId: this.entity_id });
                    }
                );
            } else {
                this.$emit('saved', 1);
            }
        }
    },
}
</script>
