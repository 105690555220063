<template>
    <div>
    </div>
</template>
<script>

export default {
    data() {
        return {
            states: {},
            errors: {},
            hasErrors: false,
            saving: false,
            loading: false
        }
    },
    props: ['id'],
    mounted() {
        if (this.id) {
            this.loading = true;
            this.load(this.id)
                .then(() => this.loading = false);
        }
    },
    methods: {
        submit: function (e) {
            e.preventDefault();  
            this.saving = true;

            this.apiRequest(apiClient => this.save(apiClient)).then(response => {
                    this.saving = false;
                    this.afterSave(response);
                    return response;
                })
                .catch(e => {
                    this.saving = false;
                    if (e.response.data.errors) {
                        this.states = {};
                        this.hasErrors = true;
                        this.errors = e.response.data.errors;
                        for (var key in this.errors) {
                            this.states[key] = false;
                        }
                    }
                });

        }

    },
}
</script>