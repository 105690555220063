<template>

    <b-row>
        <b-col>
            <div class='form'>
                <div class='form-group'>
                    <b-form-input v-model="filter.keyword" placeholder="#name"></b-form-input>
                </div>
                <b-form-group label='Item'>
                    <items-select v-model='filter.item_id' :returnId='true'/>
                </b-form-group>

                <b-form-group label='Quantity larger than or equal'>
                    <b-form-input v-model='filter.quantity' />
                </b-form-group>
            </div>
            <base-list :collection='collection' :loading='loading' :fields='fields' :modalObject='currentModalObject' @pageChanged='pageChanged'>

                    <template v-slot:cell(saleable_items)='data'>
                        <div v-for='(saleableItem, index) in data.item.saleable_items' :key='index'>
                            {{ saleableItem.item.name }}
                            {{ saleableItem.item_type }}

                            <div class='badge badge-info m-2'>
                                {{ saleableItem.quantity }} 
                                {{ saleableItem.quantity | pluralize('item', 'items')}}
                            </div>
                            <div class='badge badge-info m-2' v-if='saleableItem.period'>
                                {{ saleableItem.period }}
                            </div>
                        </div>
                    </template>
                    <template v-slot:cell(actions)='data'>
                        <button class='btn btn-primary' @click="itemClicked(data.item)">Select</button>
                    </template>
                            
            </base-list>
                
        </b-col>
        <b-col  v-if='multiple' cols='4'>
            <div>
                <h3>selected services</h3>
                <hr>
                <ul>
                    <li v-for='(service, index) in selected' :key='index' class='m-2'>
                        <button @click='removeItem(index)' class='btn btn-xxs btn-danger m-3'>x</button>
                        <b>#{{ service.id }}</b> - <b>{{ service.name }}</b>

                        <hr>
                    </li>
                </ul>
                <button class='btn btn-primary' v-if='selected.length > 0' @click="finish(selected)">Add</button>
                <div class="alert alert-primary" v-else>please select some services</div>
            </div>
        </b-col>


    </b-row>
</template>

<script>
import BaseList from '@/components/Lists/BaseList';
import BaseListMethods from '@/components/Lists/BaseListMethods';
import ItemsSelect from '../Select/ItemsSelect.vue';

export default {
    props: ['is_purchase', 'multiple'],
    components: { BaseList, ItemsSelect },
    extends: BaseListMethods,
    methods: {
        load: function (apiClient, cancelToken, filter) {
            return apiClient.get('saleables', {...filter, is_active: 1}, cancelToken);
        },
        itemClicked: function (item) {
            if (this.multiple) {
                this.selected.push(item);
            } else {
                this.finish(item);
            }
        },
        removeItem: function(index) {
            this.selected.splice(index, 1);
        },
        finish: function(item) {
            this.$emit('clickedItem', item);
            this.$emit('saved', item);
        }
    },
    data: function() {
        return {
            selected: [],
            fields: [
                { key: 'id', label: '#' },
                { key: 'name', label: 'Name' },
                { key: 'price', label: 'Price' },
                { key: 'saleable_items', label: 'Items' },
                { key: 'actions', label: '' }
            ]
        };
    },
}
</script>

