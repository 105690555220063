<template>
    <div>
        <loader v-if='loading'/>
        <b-form v-if='!loading' class='basic-form' @submit="submit" novalidate>
            <b-form-group label="Name">
                <b-form-input v-model="data.test" type="text" placeholder="">
                </b-form-input>
                <FormGroupError :errors='errors.name'/>
            </b-form-group>
            <FormErrors :hasErrors='hasErrors' :errors='errors'></FormErrors>

            <button v-if='!this.saving' type="submit" class="btn btn-primary">Save</button>

            <b-button v-if='this.saving' variant="primary" :disabled='true'>
                <b-spinner small type="grow"></b-spinner>
                saving...
            </b-button>
        </b-form>
    </div>
</template>

<script>
import BaseForm from '@/views/BaseForm'
export default {
    extends: BaseForm,
    data: function () {
        return {
            entity: {
            },
            data: {}
        }
    },
    methods: {
        load: function(id) {
            return this.apiRequest(apiClient => apiClient.get('entities/'+id))
                .then(response => {
                    this.entity = response;
                    this.data = response.data ? response.data : {};
                });
        },
        save: function (apiClient) {
            if (this.id) {
                return apiClient.put('entities/'+this.id, {
                    ... this.entity,
                    data: this.data
                });
            } else {
                return apiClient.post('entities', this.entity);
            }
        },
        afterSave: function () {
            this.$emit('saved', 1);
        }
    },
}
</script>