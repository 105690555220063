<template>
    <div>
        <loader v-if='loading' />
        <b-form v-if='!loading' class='basic-form' @submit="submit" novalidate>

            <b-form-group label="Payment Method">
                <b-col cols='6'>
                    <payment-methods-select v-model='cashPayment.payment_method_id' :returnId="true" />
                </b-col>
                <FormGroupError :errors='errors.payment_method_id' />
            </b-form-group>

            <b-form-group label="Entity" v-if='!entityId && type != "starting-balance"'>
                <b-col cols='6'>
                    <entities-select v-model='selectedEntityId' :returnId="true" :filter="entitiesFilter" />
                </b-col>
                <FormGroupError :errors='errors.entitiy_id' />
            </b-form-group>

            <b-form-group label="Date">
                <b-form-datepicker v-model="cashPayment.date"></b-form-datepicker>
            </b-form-group>
            <b-row v-for='(cashPaymentPart, index) in cashPaymentParts' :key='index' class='m-2' align-v='center'>
                <b-col cols='6'>
                    <b-row align-v="center">
                        <b-col cols='1'>
                            <b-button v-if='!cashPaymentPart.cash_return_payment_id' variant='danger'
                                class='btn btn-danger btn-xs sharp' @click='removeCashPaymentPart(index)'>
                                <i class='fa fa-remove'></i>
                            </b-button>
                        </b-col>
                        <b-col>
                            <div v-if='cashPaymentPart.transaction'>
                                {{ cashPaymentPart.transaction.title }}
                                <div v-if='cashPaymentPart.transaction.transactionable'>
                                    <div class='badge badge-light'>
                                        {{ cashPaymentPart.transaction.transactionable.started_at }}
                                    </div>
                                    -
                                    <div class='badge badge-light'>
                                        {{ cashPaymentPart.transaction.transactionable.finished_at }}
                                    </div>
                                </div>
                            </div>
                            <div v-else>
                                Down Payment
                            </div>
                        </b-col>
                        <b-col>

                            <div v-if='cashPaymentPart.transaction'>
                                <div v-if='cashPaymentPart.transaction.amount_remaining > 0'>
                                    Remaining: {{ cashPaymentPart.transaction.amount_remaining | currency }}
                                </div>
                                <div class='badge'
                                    :class="cashPaymentPart.transaction.amount_paid != cashPaymentPart.transaction.amount ? 'badge-danger' : 'badge-success'">
                                    {{ cashPaymentPart.transaction.amount_paid | currency }} / {{
                                        cashPaymentPart.transaction.amount | currency }}
                                </div>
                            </div>
                        </b-col>
                    </b-row>
                </b-col>
                <b-col cols='2'>
                    <div v-if='cashPaymentPart.transaction'>
                        <div class='badge' :class='statusClass[cashPaymentPart.transaction.status]'>
                            {{ cashPaymentPart.transaction.status }}
                        </div>
                    </div>
                </b-col>
                <b-col>
                    <div v-if='cashPaymentPart.cash_return_payment_id'>
                        {{ cashPaymentPart.amount }}
                    </div>
                    <b-form-input v-else v-model='cashPaymentPart.amount' type='number' min='0' />
                </b-col>
            </b-row>

            <b-row v-if='selectedEntityId' align-v='center' class='m-2'>
                <b-col class='text-right p-3' cols='8'>
                    <b>
                        Total Required
                    </b>
                </b-col>
                <b-col>
                    {{ totalCashPaymnetPartsAmount }}
                </b-col>
            </b-row>
            <div class='btn btn-xs btn-primary mb-4' v-if='selectedEntityId'
                @click="modal('transactionsSelector', { multiple: true, filter: { entity_id: selectedEntityId, unpaid: 1, not_in_ids: transactionIds } }, 'addtransactions')">
                Add Other transactions</div>

            <b-form-group label="Total Amount">
                <b-form-input v-model='cashPayment.amount' type='number' min='0' />
            </b-form-group>
            <h3>
                <div class='badge badge-light'>
                    Remaining Amount:
                    {{ cashPayment.amount - totalCashPaymnetPartsAmount | currency }}
                </div>
            </h3>
            <b-form-group v-if='selectedEntityId && false'>
                <b-form-checkbox v-model="cashPayment.auto_settle" value="1" unchecked-value="0">
                    Auto Settle transactions amount
                </b-form-checkbox>
            </b-form-group>

            <FormErrors :hasErrors='hasErrors' :errors='errors'></FormErrors>

            <button v-if='!this.saving' type="submit" class="btn btn-primary">Save</button>

            <b-button v-if='this.saving' variant="primary" :disabled='true'>
                <b-spinner small type="grow"></b-spinner>
                saving...
            </b-button>
        </b-form>

        <Modals :modalName='currentModal' :modalData='currentModalData' @saved='modalCallback' @closed='modalClosed'>
        </Modals>
    </div>
</template>

<script>
import BaseForm from '@/views/BaseForm'
import EntitiesSelect from '../../components/Select/EntitiesSelect.vue';
import PaymentMethodsSelect from '../../components/PaymentMethodsSelect.vue';

export default {
    components: { EntitiesSelect, PaymentMethodsSelect },
    extends: BaseForm,
    props: ['type', 'entityId'],
    data: function () {
        return {
            cashPayment: {
                amount: 0,
                date: this.today()
            },
            remainingTransactionsCount: 0,
            cashPaymentParts: [],
            selectedEntityId: null,
            downPaymentAmount: 0,
            statusClass: {
                'in progress': 'badge-warning',
                'finished': 'badge-success',
                'pending': 'badge-light',
            },
            entitiesFilter: {}
        }
    },
    mounted() {
        var defaultEntityId = null;
        var user = this.loadUser();
        console.log(user);
        if (!this.entityId) {
            var types = [];
            if (this.type == 'cash-in') {
                types = ['customer', 'kid'];
                if (typeof user.defaultEntities.customer !== 'undefined') {
                    defaultEntityId = user.defaultEntities.customer.value;
                }
            } else if (this.type == 'cash-out') {
                types = ['supplier'];
                if (typeof user.defaultEntities.supplier !== 'undefined') {
                    defaultEntityId = user.defaultEntities.supplier.value;
                }
            } else if (this.type == 'balance-in') {
                types = ['owner'];
                if (typeof user.defaultEntities.owner !== 'undefined') {
                    defaultEntityId = user.defaultEntities.owner.value;
                }
            } else if (this.type == 'balance-out') {
                types = ['owner'];
                if (typeof user.defaultEntities.owner !== 'undefined') {
                    defaultEntityId = user.defaultEntities.owner.value;
                }
            } else {
                types = ['kid'];
            }

            this.entitiesFilter = {
                types: types
            };

            this.selectedEntityId = defaultEntityId
        } else {
            this.apiRequest(apiClient => apiClient.get('entities/' + this.entityId))
                .then(response => this.selectedEntityId = response.id);
        }
    },
    methods: {
        load: function (id) {
            return this.apiRequest(apiClient => apiClient.get('cash-payments/' + id))
                .then(response => {
                    this.cashPayment = response;
                    this.cashPaymentParts = response.cash_payment_parts.filter(item => (item.transaction || item.cash_return_payment_id) ? true : false);
                    this.selectedEntityId = response.entity.id;
                });
        },
        save: function (apiClient) {
            let cashRegisterId = localStorage.cashRegisterId;
            var cashPayment = {
                cash_register_id: cashRegisterId,
                entity_id: this.selectedEntityId,
                date: this.cashPayment.date,
                payment_method_id: this.cashPayment.payment_method_id,
                amount: this.cashPayment.amount,
                type: this.type,
                cash_payment_parts: this.cashPaymentParts.map(
                    cashPaymentPart => ({
                        id: cashPaymentPart.id,
                        transaction_id: cashPaymentPart.transaction ? cashPaymentPart.transaction.id : null,
                        amount: cashPaymentPart.amount
                    })
                )
            }
            if (this.id) {
                return apiClient.put('cash-payments/' + this.id, cashPayment);
            } else {

                return apiClient.post('cash-payments', cashPayment);
            }
        },
        afterSave: function () {
            this.$emit('saved', 1);
        },
        addtransactions: function (transactions) {
            transactions.forEach(transaction => {

                // var amount = transaction.amount_remaining;
                // var remainingAmount = parseFloat(this.cashPayment.amount) - this.totalCashPaymnetPartsAmount;
                // if (remainingAmount < transaction.amount_remaining) {
                //     amount = remainingAmount;
                // }
                this.cashPaymentParts.push({
                    transaction: transaction,
                    amount: transaction.amount_remaining,
                });
            })
        },
        removeCashPaymentPart(index) {
            this.cashPaymentParts.splice(index, 1);
        }
    },
    watch: {
        selectedEntityId: function () {
            if (this.id) {
                return;
            }

            this.cashPaymentParts = [];
            if (!this.selectedEntityId) {
                return;
            }
            var filter = { entity_id: this.selectedEntityId, unpaid: 1, not_in_ids: this.transactionIds };
            this.apiRequest(apiClient => apiClient.get('transactions', filter))
                .then(response => {
                    this.addtransactions(response.data);
                });
        }
    },
    computed: {
        totalCashPaymnetPartsAmount: function () {
            return this.cashPaymentParts.reduce((total, item) => {
                return total + parseFloat(item.amount)
            }, 0);
        },
        transactionIds: function () {
            return this.cashPaymentParts.map(item => item.transaction ? item.transaction.id : null);
        },
        totalPaymentAmount: function () {
            return parseFloat(this.cashPayment.downpayment_amount ?? 0) + parseFloat(this.totalCashPaymnetPartsAmount);
        }
    }
}
</script>