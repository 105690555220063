<template>
    <div>
        <b-form-group label="Discount Group">
            <price-groups-select v-model='entity.price_group_id' :returnId='true'/>
        </b-form-group>
        <b-form-group label='Phone Numbers' class='mt-5 mb-4'>
            <b-row>
                <b-col>
                    <b>Name</b>
                </b-col>
                <b-col>
                    <b><i class='fa fa-phone'></i> Number</b>
                </b-col>
                <b-col>
                </b-col>
                <b-col>
                </b-col>
            </b-row>
            <b-row  v-for='(phoneNumber, index) in entity.entity_phones' :key='index' class='mt-4'  align-v='center'>
                <b-col>
                    <b-form-input v-model="phoneNumber.name" type="text" placeholder="Name">
                    </b-form-input>
                </b-col>
                <b-col>
                    <b-form-input v-model="phoneNumber.number" type="text" placeholder="Number">
                    </b-form-input>
                </b-col>
                <b-col>

                    <b-form-radio
                        v-model="entity.primary_phone_index"
                        :value="index"
                    >
                        Primary
                    </b-form-radio>
                </b-col>
                <b-col>
                    <b-button variant='danger' class='m-2 btn btn-danger btn-xs sharp' @click='removePhoneNumber(index)'>
                        <i class='fa fa-remove'></i>
                    </b-button>
                </b-col>
            </b-row>
            <div class='m-2'>
                <div class='btn btn-xs btn-success' @click='addPhoneNumber()'>Add phone</div>
            </div>
            
            <div class='mt-5'>
                <b-form-group>
                    <b-form-checkbox
                        v-model="entity.deliver_without_sale"
                        value="1"
                        unchecked-value="0"
                    >
                        Deliver Items Without Sale
                    </b-form-checkbox>
                </b-form-group>
                <b-form-group>
                    <b-form-checkbox
                        v-model="entity.is_active"
                        value="1"
                        unchecked-value="0"
                    >
                        Active
                    </b-form-checkbox>
                </b-form-group>

                <b-form-group label='Active From'>
                    <b-form-datepicker
                        v-model="entity.active_from"
                        today-button
                        reset-button
                        close-button
                    />
                </b-form-group>

                <b-form-group label='Active To'>
                    <b-form-datepicker
                        v-model="entity.active_to"
                        today-button
                        reset-button
                        close-button
                    />
                </b-form-group>
            </div>

        </b-form-group>
        
    </div>
</template>

<script>
import PriceGroupsSelect from '../Select/PriceGroupsSelect.vue';
export default {
  components: { PriceGroupsSelect },
    props: ['value'],
    data: function () {
        return {
            entity: {},
            updatingValue: false
        }
    },
    mounted() {
        if (this.value) {
            this.entity = this.value;
        }
    },
    methods: {
        updateValue() {            
            this.$emit('input', this.entity);
        },
        addPhoneNumber() {
            if (!this.entity.entity_phones) {
                this.entity = {...this.entity , entity_phones: []};
            }
            
            this.entity.entity_phones.push({});
        },
        removePhoneNumber(index) {
            this.entity.entity_phones.splice(index, 1);
        }
    },
    watch: {
        value: {
            immediate: true,
            handler() {
                if (this.value) {
                    this.entity = this.value;
                    if (!this.entity.primary_phone_index) {
                        
                        if (this.entity.primary_phone_id) {
                            var primaryPhoneIndex = this.entity.entity_phones.findIndex(item => item.id == this.entity.primary_phone_id);
                            if (primaryPhoneIndex != this.entity.primary_phone_index) {
                                this.entity = {...this.entity, primary_phone_index: primaryPhoneIndex};
                            }
                        }
                    }
                } else {
                    this.entity = {
                    };
                }
                
            }
        },
        entity: {
            deep: true,
            handler: function () {
                this.updateValue();
            }
        }
    }
}
</script>