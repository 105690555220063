<template>

    <b-row>

        <b-col>
            <div class='form'>
                <div class='form-group'>
                    
                </div>
            </div>
            <base-list :collection='collection' :loading='loading' :fields='fields' :modalObject='currentModalObject' @pageChanged='pageChanged'>

                    <template v-slot:cell(status)='data'>

                        <div class='badge' :class='statusClass[data.item.status]'>
                        {{ data.item.status }}
                        </div>
                    </template>

                    <template v-slot:cell(amount)='data'>
                        
                        {{ data.item.amount_remaining | currency }}
                        <br>
                        <div class='badge badge-danger'>
                            {{ data.item.amount_paid | currency }} / {{ data.item.amount | currency }}
                        </div>                        
                    </template>
                    <template v-slot:cell(actions)='data'>
                        <button class='btn btn-primary' @click="itemClicked(data.item)">Select</button>
                    </template>
                            
            </base-list>
                
        </b-col>
        <b-col  v-if='multiple' cols='3'>
            <div>
                <h5>selected transactions</h5>
                <hr>
                <ul>
                    <li v-for='(transaction, index) in selected' :key='index' class='m-2'>
                        <button @click='removeItem(index)' class='btn btn-xxs btn-danger m-3'>x</button>
                        <b>#{{ transaction.id }}</b> - <b>{{ transaction.title }}</b>

                        <hr>
                    </li>
                </ul>
                <button class='btn btn-primary' v-if='selected.length > 0' @click="finish(selected)">Add</button>
                <div class="alert alert-primary" v-else>please select some transactions</div>
            </div>
        </b-col>


    </b-row>
</template>

<script>
import BaseList from '@/components/Lists/BaseList';
import BaseListMethods from '@/components/Lists/BaseListMethods';

export default {
    props: ['multiple'],
    components: { BaseList },
    extends: BaseListMethods,
    methods: {
        load: function (apiClient, cancelToken) {
            var filter = this.innerFilter;
            if (this.filter) {
                filter = {...this.filter, ...this.innerFilter};
            }

            return apiClient.get('transactions', filter, cancelToken);
        },
        itemClicked: function (item) {
            if (this.multiple) {
                this.selected.push(item);
            } else {
                this.finish(item);
            }
        },
        removeItem: function(index) {
            this.selected.splice(index, 1);
        },
        finish: function(item) {
            this.$emit('clickedItem', item);
            this.$emit('saved', item);
        }
    },
    data: function() {
        return {
            innerFilter: {},
            selected: [],
            statusClass: {
                'in progress': 'badge-warning',
                'finished': 'badge-success',
                'pending': 'badge-light',
            },
            fields: [
                { key: 'id', label: '#' },
                { key: 'title', label: 'transaction' },
                { key: 'amount', label: 'amount remaining' },
                { key: 'status', label: 'status' },
                { key: 'actions', label: '' }
            ]
        };
    },
    watch: {
        innerFilter: {
            deep: true,
            handler: function () {
                this.page = 1;
                this.refresh();
            }
        }
    }
}
</script>

