<template>
    <div>
    </div>
</template>

<script>
export default {
    // props: [showModal]
    data() {
        return {
            modalShow: true
        }
    },
    methods: {
        saved: function(data) {
            this.modalShow = false;
            this.$emit('saved', data);
        },
        closed: function() {
            this.$emit('closed');
        }
    }
}
</script>