<template>
    <div>
        <loader v-if='loading'/>
        <b-form v-if='!loading' class='basic-form' @submit="submit" novalidate>
            <b-form-group label="Name">
                <b-form-input v-model="entity.name" :state="states.name" type="text" placeholder="">
                </b-form-input>
                <FormGroupError :errors='errors.name'/>
            </b-form-group>
            <b-form-group label="Type">
                <v-select v-model='entity.type' :options='["supplier", "customer", "owner"]'/>
                <FormGroupError :errors='errors.type'/>
            </b-form-group>

            <entity-form-part v-model='entity'/>
            
            <FormErrors :hasErrors='hasErrors' :errors='errors'></FormErrors>

            <button v-if='!this.saving' type="submit" class="btn btn-primary">Save</button>

            <b-button v-if='this.saving' variant="primary" :disabled='true'>
                <b-spinner small type="grow"></b-spinner>
                saving...
            </b-button>
        </b-form>
    </div>
</template>

<script>
import BaseForm from '@/views/BaseForm'
import EntityFormPart from '../../components/Forms/EntityFormPart.vue';
export default {
  components: { EntityFormPart },
    extends: BaseForm,
    data: function () {
        return {
            entity: {
            }
        }
    },
    methods: {
        load: function(id) {
            return this.apiRequest(apiClient => apiClient.get('entities/'+id))
                .then(response => this.entity = response);
        },
        save: function (apiClient) {
            if (this.id) {
                return apiClient.put('entities/'+this.id, this.entity);
            } else {
                return apiClient.post('entities', this.entity);
            }
        },
        afterSave: function () {
            this.$emit('saved', 1);
        }
    },
}
</script>