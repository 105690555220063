<template>
    <div>
        <loader v-if='loading' />
        <b-form v-else class='basic-form' @submit="submit" novalidate>
            <h2>Are you sure you will delete all the following : </h2>
            <div v-for='(relation, index) in relations' :key='index'>
                <b-form-checkbox v-model="confirmedActions[index]" :value="1" unchecked-value="0">
                    {{ relation }}
                </b-form-checkbox>
            </div>

            <FormErrors :hasErrors='hasErrors' :errors='errors'></FormErrors>

            <button v-if='!this.saving' type="submit" class="btn btn-primary">Delete</button>

            <b-button v-if='this.saving' variant="primary" :disabled='true'>
                <b-spinner small type="grow"></b-spinner>
                saving...
            </b-button>
        </b-form>
    </div>
</template>
<script>
import BaseForm from '../../components/BaseForm.vue'
export default {
    extends: BaseForm,
    props: ['id'],
    data: function () {
        return {
            confirmedActions: {},
            relations: [],
        }
    },
    methods: {
        load: function (id) {
            return this.apiRequest(apiClient => apiClient.get('entities/relations/' + id))
                .then(response => {
                    this.relations = response;
                });
        },
        save: function (apiClient) {
            if (this.id) {
                return apiClient.delete('entities/' + this.id, { 'confirmedActions': this.confirmedActions });
            }
        },
        afterSave: function () {
            this.$emit('saved', 1);
        }
    },
}
</script>
