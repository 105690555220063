<template>
    <div>
        <loader v-if='loading'/>
        <b-form v-if='!loading' class='basic-form' @submit="submit" novalidate>
            <b-form-group label="Name">
                <b-form-input v-model="saleable.name" :state="states.name" type="text" placeholder="">
                </b-form-input>
                <FormGroupError :errors='errors.name'/>
            </b-form-group>
            <b-form-group label="Group">
                <saleable-group-select v-model='saleable.saleable_group_id' :returnId='true'/>
            </b-form-group>
            <b-form-group>
                <b-form-checkbox
                    v-model="saleable.custom_price"
                    value="1"
                    unchecked-value="0"
                >
                    Custom Price
                </b-form-checkbox>
            </b-form-group>
            <b-form-group>
                <b-form-checkbox
                    v-model="saleable.is_active"
                    value="1"
                    unchecked-value="0"
                >
                    Is Active
                </b-form-checkbox>
            </b-form-group>
            
            <b-form-group>
                <b-form-radio-group
                    v-model="saleable.type"
                    :options="['sale', 'purchase']"
                ></b-form-radio-group>
            </b-form-group>
        

            <b-form-group label='Sold to' v-if='saleable.type == "sale"'>
                <b-form-radio-group
                    v-model="saleable.entity_type"
                    :options="['kid', 'customer']"
                ></b-form-radio-group>
            </b-form-group>

            <b-form-group label="Price" >
                <b-form-input v-model="saleable.price" :state="states.price" type="number" placeholder="">
                </b-form-input>
                <FormGroupError :errors='errors.price'/>
            </b-form-group>

            <div v-for='(saleableItem, index) in saleableItems' :key='index'>
                <b-row class='mt-4 mb-4' align-v="center">
                    <b-col cols=4>
                        <b>{{ saleableItem.item.name }}</b>
                    </b-col>
                    <b-col cols=2>
                        <b-form-input v-model="saleableItem.quantity" type="number" placeholder="">
                        </b-form-input>
                    </b-col>
                    <b-col cols=2>
                        <b-button variant='danger' class='m-2 btn btn-danger btn-xs sharp' @click='removeSaleableItem(index)'>
                            <i class='fa fa-remove'></i>
                        </b-button>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols=2 class='text-right'>
                        <b>
                        Default Period
                        </b>
                    </b-col>
                    <b-col>  
                        <b-form-radio-group
                            v-model="saleableItem.period"
                            :options="periodOptions"
                        ></b-form-radio-group>
                    </b-col>
                </b-row>
                <hr>
            </div>
            <div class='btn btn-xs btn-primary m-2' @click="modal(
                'itemsSelector',
                {
                    multiple: true,
                    filter: {
                        not_in_ids: itemIds,
                        sale: saleable.type == 'sale' ? 1 : 0,
                        purchase: saleable.type == 'purchase' ? 1 : 0,
                        sold_to: saleable.type == 'purchase' ? null : saleable.entity_type,
                        purchased_from: saleable.type == 'purchase' ? 'supplier' : null
                    }
                }, 'addItems')">Add (Service / Product)</div>
            <br>
            <FormErrors :hasErrors='hasErrors' :errors='errors'></FormErrors>

            <button v-if='!this.saving' type="submit" class="btn btn-primary">Save</button>

            <b-button v-if='this.saving' variant="primary" :disabled='true'>
                <b-spinner small type="grow"></b-spinner>
                saving...
            </b-button>
        </b-form>
		<Modals :modalName='currentModal' :modalData='currentModalData' @saved='modalCallback' @closed='modalClosed'>
		</Modals>
    </div>
</template>

<script>
import BaseForm from '@/views/BaseForm'
import SaleableGroupSelect from '../../components/Select/SaleableGroupSelect.vue';
export default {
  components: { SaleableGroupSelect },
    extends: BaseForm,
    data: function () {
        return {
            saleable: {
                type: 'sale'
            },
            saleableItems: [],
            periodOptions: ['unlimited', 'weekly', 'monthly', 'yearly'],
        }
    },
    methods: {
        load: function(id) {
            return this.apiRequest(apiClient => apiClient.get('saleables/'+id))
                .then(response => {
                    this.saleable = response;
                    this.saleableItems = response.saleable_items;
                });
        },
        save: function (apiClient) {
            var saleable = {
                ...this.saleable,
                saleable_items: this.saleableItems.map(saleableItem => ({
                    item_id: saleableItem.item.id,
                    quantity: saleableItem.quantity,
                    period: saleableItem.period,
                }))
            }
            if (this.id) {
                return apiClient.put('saleables/'+this.id, saleable);
            } else {
                return apiClient.post('saleables', saleable);
            }
        },
        afterSave: function () {
            this.$emit('saved', 1);
        },
        addItems: function (items) {

            items.forEach(item => {    
                this.saleableItems.push({
                    item: item,
                    quantity: 1,
                    period: 'unlimited'
                });
            });
        },
        removeSaleableItem(index) {
            this.saleableItems.splice(index, 1);
        }
    },
    computed: {
        itemIds: function () {
            return this.saleableItems.map(saleableItem => saleableItem.item.id);
        }
    },
    watch: {
        "saleable.type": function () {
            if (this.saleable.type == 'purchase') {
                this.saleable.entity_type = 'supplier';
            } else {
                this.saleable.entity_type = 'kid';
            }
        }
    }
}
</script>