<template>
    <div>
        <loader v-if='loading'/>
        <b-form v-if='!loading' class='basic-form' @submit="submit" novalidate>

            <b-form-group label="Entity" v-if='!entityId'>
                <b-col cols='6'>
                    <entities-select  v-model='entityNote.entity_id' :returnId='true'/>
                </b-col>
                <FormGroupError :errors='errors.entitiy_id'/>
            </b-form-group>
            <b-form-group label="Note">
                <b-textarea v-model="entityNote.note" :state="states.note" type="text" placeholder=""/>
                <FormGroupError :errors='errors.note'/>
            </b-form-group>
            
            <b-form-group>
                <b-form-checkbox
                    v-model="entityNote.is_pinned"
                    value="1"
                    unchecked-value="0"
                >
                    Pinned
                </b-form-checkbox>
            </b-form-group>
            <FormErrors :hasErrors='hasErrors' :errors='errors'></FormErrors>

            <button v-if='!this.saving' type="submit" class="btn btn-primary">Save</button>

            <b-button v-if='this.saving' variant="primary" :disabled='true'>
                <b-spinner small type="grow"></b-spinner>
                saving...
            </b-button>
        </b-form>
    </div>
</template>

<script>
import BaseForm from '@/views/BaseForm'
import EntitiesSelect from '../../components/Select/EntitiesSelect.vue';
export default {
    props: ['entityId'],
    components: { EntitiesSelect },
    extends: BaseForm,
    data: function () {
        return {
            entityNote: {
                is_pinned: 0
            }
        }
    },
    methods: {
        load: function(id) {
            return this.apiRequest(apiClient => apiClient.get('entity-notes/'+id))
                .then(response => this.entityNote = response);
        },
        save: function (apiClient) {
            if (this.id) {
                return apiClient.put('entity-notes/'+this.id, this.entityNote);
            } else {
                return apiClient.post('entity-notes', this.entityNote);
            }
        },
        afterSave: function () {
            this.$emit('saved', 1);
        }
    },
    watch: {
        entityId: {
            immediate: true,
            handler: function () {
                this.entityNote.entity_id = this.entityId;
            }
        }
    }
}
</script>