<template>
    <div class="p-4">
        <b-form class='basic-form' @submit="submit" novalidate>
            <b-form-group :label="$t('auth.name')">
                <b-form-input v-model="client.name" :state="states.name" type="text" placeholder="">
                </b-form-input>
                <FormGroupError :errors='errors.name' />
            </b-form-group>

            <b-form-group :label="$t('auth.workspaceLogo')">
                <b-form-file accept="image/*" v-model="client.logo" @change="onFileChange" :state="states.logo" type="file">
                </b-form-file>
                <FormGroupError :errors='errors.client_logo' />
            </b-form-group>
            <div id="preview">
                <img v-if="previewUrl" :src="previewUrl" />
            </div>

            <FormErrors :hasErrors='hasErrors' :errors='errors'></FormErrors>

            <div class="d-flex justify-content-between mt-4 mb-2">
                <button v-if='!this.saving' type="submit" class="btn btn-primary">{{ $t('auth.update') }}</button>

                <b-button v-if='this.saving' variant="primary" :disabled='true'>
                    <b-spinner small type="grow"></b-spinner>
                    saving...
                </b-button>
            </div>

        </b-form>
    </div>
</template>

<script>
import BaseForm from '@/views/BaseForm'
export default {
    extends: BaseForm,
    data: function () {
        return {
            previewUrl: null,
            client: {
            }
        }
    },
    created() {
        let client = this.loadUser().currentClient;
        this.client = {name: client.name, id: client.id};
    },
    methods: {
        onFileChange(e) {
            const file = e.target.files[0];
            if (file) {
                this.previewUrl = URL.createObjectURL(file);
            } else {
                this.previewUrl = null;
            }
        },
        save: function (apiClient) {
            let clientData = new FormData();
            clientData.append('name', this.client.name);
            if (this.client.logo) {
                clientData.append('logo', this.client.logo);
            }
            apiClient.headers = {
                "Content-Type": "multipart/form-data"
            };
            return apiClient.post('clients/'+this.client.id, clientData);
        },
        afterSave: function () {
            window.location.reload();
            this.$emit('saved', 1);
        }
    }
}
</script>

<style>
#preview {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

#preview img {
    max-width: 100%;
    max-height: 100px;
}
</style>