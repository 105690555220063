<template>
    <div>
        <loader v-if='loading'/>
        <b-form v-if='!loading' class='basic-form' @submit="submit" novalidate>
            <b-form-group label="Name">
                <b-form-input v-model="item.name" :state="states.name" type="text" placeholder="">
                </b-form-input>
                <FormGroupError :errors='errors.name'/>
            </b-form-group>
            <b-form-group label="Type">
                <b-form-radio-group
                    v-model="item.type"
                    :options="types"
                ></b-form-radio-group>
                
                <FormGroupError :errors='errors.type'/>
            </b-form-group>

            <b-form-group>
                <b-form-checkbox
                    v-model="item.deliver_without_sale"
                    value="1"
                    unchecked-value="0"
                >
                    Deliver without sale
                </b-form-checkbox>
            </b-form-group>
            <b-row>
                <b-col cols=5>
                    <b-form-group label="max amount per day">
                        <b-form-input v-model='item.max_amount_per_day'/>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols=1>
                    <b-form-group>
                        <b-form-checkbox
                            v-model="item.sale"
                            value="1"
                            unchecked-value="0"
                        >
                            Sale
                        </b-form-checkbox>
                    </b-form-group>
                </b-col>
                <b-col cols=1>
                    <b-form-group>
                        <b-form-checkbox
                            v-model="item.purchase"
                            value="1"
                            unchecked-value="0"
                        >
                            Purchase
                        </b-form-checkbox>
                    </b-form-group>
                </b-col>
            </b-row>

            <b-form-group label='sold to' v-if='item.sale == 1'>
                <b-form-radio-group
                    v-model="item.sold_to"
                    :options="soldToTypes"
                ></b-form-radio-group>
            </b-form-group>
            

            <div v-for='(saleableGroup, index) in saleableGroups' :key='index'>

                <h3>{{ saleableGroup.type }}</h3>
                <b-row>
                    <b-col cols=4>
                        <b>Saleable Name</b>
                    </b-col>
                    <b-col cols=2>
                        <b>Quantity</b>
                    </b-col>
                    <b-col cols=2>
                        <b>Price</b>
                    </b-col>
                </b-row>
                <div v-if='saleableGroup.saleableItems.length == 0' class='p-5 text-center'>
                    <p>
                    add one or more prices
                    </p>
                </div>
                <div v-for='(saleableItem, index) in saleableGroup.saleableItems' :key='index'>
                    <b-row class='mt-4 mb-4' align-v="center">
                        <b-col cols=4>
                            <b-input type='text' v-model='saleableItem.saleable.name'/>
                        </b-col>
                        <b-col cols=2>
                            <b-form-input v-model="saleableItem.quantity" type="number" placeholder="">
                            </b-form-input>
                        </b-col>
                        <b-col cols=2>
                            <b-form-input v-model="saleableItem.saleable.price" type="number" placeholder="">
                            </b-form-input>
                        </b-col>
                        <b-col cols=2>

                            <b-form-checkbox
                                v-model="saleableItem.saleable.custom_price"
                                value="1"
                                unchecked-value="0"
                            >
                                Custom Price
                            </b-form-checkbox>
                            <br>
                            <b-form-checkbox
                                v-model="saleableItem.saleable.is_active"
                                value="1"
                                unchecked-value="0"
                            >
                                Is Active
                            </b-form-checkbox>
                        </b-col>
                        <b-col cols=2>
                            <b-button variant='danger' class='m-2 btn btn-danger btn-xs sharp' @click='removeSaleableItem(saleableGroup.type, index)'>
                                <i class='fa fa-remove'></i>
                            </b-button>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols=2 class='text-right'>
                            <b>
                            Default Period
                            </b>
                        </b-col>
                        <b-col>  
                            <b-form-radio-group
                                v-model="saleableItem.period"
                                :options="periodOptions"
                            ></b-form-radio-group>
                        </b-col>
                    </b-row>
                    <hr>
                </div>
            </div>


            <div class='m-2'>
                <div class='btn btn-xs btn-success' v-if='item.sale == 1' @click='addSalePrice()'>Add Sale Price</div>
            </div>
            
            <div class='m-2'>
                <div class='btn btn-xs btn-success' v-if='item.purchase == 1' @click='addPurchasePrice()'>Add Purchase Price</div>
            </div>
            
            <FormErrors :hasErrors='hasErrors' :errors='errors'></FormErrors>

            <button v-if='!this.saving' type="submit" class="btn btn-primary">Save</button>

            <b-button v-if='this.saving' variant="primary" :disabled='true'>
                <b-spinner small type="grow"></b-spinner>
                saving...
            </b-button>
        </b-form>
    </div>
</template>

<script>
import BaseForm from '@/views/BaseForm'
export default {
    extends: BaseForm,
    props: ['type', 'soldTo', 'purchasedFrom', 'sale', 'purchase'],
    data: function () {
        return {
            item: {
                sold_to: 'kid'
            },
            saleable: {
                'is_active': 1
            },
            saleSaleableItems: [],
            purchaseSaleableItems: [],
            periodOptions: ['unlimited', 'weekly', 'monthly', 'yearly'],
            types: ['product', 'service'],
            soldToTypes: ['kid', 'customer']
        }
    },
    mounted() {
        if (!this.id) {
            if (this.sale) {
                this.addSalePrice();
            }
            if (this.purchase) {
                this.addPurchasePrice();
            }
            this.item = {
                ... this.item,
                sale: this.sale ? 1 : 0,
                purchase: this.purchase ? 1 : 0,
                soldTo: this.soldTo,
                type: this.type,
            };
        }
    },
    methods: {
        load: function(id) {
            return this.apiRequest(apiClient => apiClient.get('items/'+id))
                .then(response => {
                    this.item = response;
                    this.saleSaleableItems = response.saleable_items
                        .filter(item => (item.saleable && item.saleable.type == 'sale') ? true : false);
                    this.purchaseSaleableItems = response.saleable_items
                        .filter(item => (item.saleable && item.saleable.type == 'purchase') ? true : false);
                });
        },
        save: function (apiClient) {
            var item = {...this.item, saleable_items: [...this.saleSaleableItems, ...this.purchaseSaleableItems]};
            if (this.id) {
                return apiClient.put('items/'+this.id, item);
            } else {
                return apiClient.post('items', item);
            }
        },
        afterSave: function () {
            this.$emit('saved', 1);
        },
        addSalePrice: function () {
            this.saleSaleableItems.push({saleable: {name: "", price: '', type: 'sale', is_active: 1}, period: 'unlimited', quantity:1});
        },
        addPurchasePrice: function () {
            this.purchaseSaleableItems.push({saleable: {name: "", price: '', type: 'purchase', is_active: 1}, period: 'unlimited', quantity:1});
        },
        removeSaleableItem: function (group, index) {
            if (group == 'sale') {
                this.saleSaleableItems.splice(index, 1);
            } else {
                this.purchaseSaleableItems.splice(index, 1);
            }
        }
    },
    computed: {
        saleableGroups: function () {
            var groups = [];
            if (this.item.sale == 1) {
                groups.push({
                    type: "sale",
                    saleableItems: this.saleSaleableItems
                });
            }
            
            if (this.item.purchase == 1) {
                groups.push({
                    type: "purchase",
                    saleableItems: this.purchaseSaleableItems
                });
            }

            return groups;
        }
    }
}
</script>