<template>
    <div>
        <loader v-if='loading'/>
        <b-form v-else class='basic-form' @submit="submit" novalidate>
            <h2>Delete</h2>
            <div v-for='(cashPaymentPart, index) in cashPayment.cash_payment_parts' :key='index'>
                <b-form-checkbox
                    v-if='cashPaymentPart.transaction'
                    v-model="transactionIds"
                    :value="cashPaymentPart.transaction.id"
                    unchecked-value="0"
                >
                    {{ cashPaymentPart.transaction.title }}

                    <div v-if='cashPaymentPart.transaction.transactionable'>
                        <div class='badge badge-light'>
                            {{ cashPaymentPart.transaction.transactionable.started_at }}
                        </div>
                        -
                        <div class='badge badge-light'>
                            {{ cashPaymentPart.transaction.transactionable.finished_at }}
                        </div>
                    </div>
                </b-form-checkbox>
            </div>

            <FormErrors :hasErrors='hasErrors' :errors='errors'></FormErrors>

            <button v-if='!this.saving' type="submit" class="btn btn-primary">Delete</button>

            <b-button v-if='this.saving' variant="primary" :disabled='true'>
                <b-spinner small type="grow"></b-spinner>
                saving...
            </b-button>
        </b-form>
    </div>
</template>
<script>
import BaseForm from '../../components/BaseForm.vue'
export default {
    extends: BaseForm,
    props: ['id'],
    data: function () {
        return {
            cashPayment: null,
            transactionIds: []
        }
    },
    methods: {
        load: function(id) {
            return this.apiRequest(apiClient => apiClient.get('cash-payments/'+id))
                .then(response => {
                    this.cashPayment = response;
                    this.transactionIds = response.cash_payment_parts.map(item => item.transaction_id);
                });
        },
        save: function (apiClient) {
            if (this.id) {
                return apiClient.delete('cash-payments/'+this.id, {"with_transactions": this.transactionIds});
            }
        },
        afterSave: function () {
            this.$emit('saved', 1);
        }
    },
}
</script>
