<template>
    <div>
        <loader v-if='loading' />
        <b-form v-if='!loading' class='basic-form' @submit="submit" novalidate>
            <b-form-group label="Entity" v-if='!entityId'>
                <b-col cols='6'>
                    <entities-select v-model='entity' :filter='{ type: entityType }' />
                </b-col>
                <FormGroupError :errors='errors.entitiy_id' />
            </b-form-group>
            <div class='btn btn-xs btn-primary m-2' v-if='entity'
                @click="modal('saleablesSelector', { multiple: true, filter: { entity_type: entity ? entity.type : entityType, is_active: 1 } }, 'addSaleables')">
                Select Saleables</div>

            <div v-if='entitySaleables.length == 0' class='m-5'>
                <h4>
                    Select one or more services
                </h4>
            </div>
            <div v-else class='mt-5 mb-5'>

                <b-form-group label="Date">
                    <b-form-datepicker v-model="cashPaymentDate"></b-form-datepicker>
                </b-form-group>
                <b-row v-for='(entitySaleable, index) in entitySaleables' :key='index' align-v='center'
                    class='p-3 border-bottom'>

                    <b-col cols='5'>
                        <b-row>
                            <b-col cols='1'>
                                <b-button variant='danger' class='btn btn-danger btn-xs sharp'
                                    @click='removeEntitySaleable(index)'>
                                    <i class='fa fa-remove'></i>
                                </b-button>
                            </b-col>
                            <b-col cols='11'>
                                <div>
                                    <b>
                                        {{ entitySaleable.saleable.name }}
                                    </b>
                                </div>
                                <div
                                    v-if='!entitySaleable.entity_saleable_items || entitySaleable.entity_saleable_items.length == 0'>
                                    <b-form-datepicker v-model="entitySaleable.started_at" today-button reset-button
                                        close-button class="mb-2"></b-form-datepicker>
                                    <b-form-datepicker v-model="entitySaleable.finished_at" today-button reset-button
                                        close-button class="mb-2"></b-form-datepicker>

                                </div>
                                <b-form-group class="mt-4"
                                    v-if="entitySaleable.separate == 0 || typeof entitySaleable.separate == 'undefined'">
                                    <b-form-checkbox v-model="entitySaleable.recurring_monthly" value="1"
                                        unchecked-value="0">
                                        Recurring Monthly
                                    </b-form-checkbox>

                                </b-form-group>
                                <div v-for='(entitySaleableItem, index) in entitySaleable.entity_saleable_items'
                                    :key='index'>

                                    <div>

                                        <div class='badge badge-info m-2'>
                                            {{ entitySaleableItem.item.name }}
                                        </div>
                                        <div class='badge badge-info ml-2'>
                                            {{ entitySaleableItem.quantity }} {{ entitySaleableItem.quantity |
                                                pluralize('item', 'items') }}
                                        </div>

                                        <b-form-group label='delivered quantity'>
                                            <b-form-spinbutton v-if='entitySaleableItem.all_delivered != 1'
                                                v-model="entitySaleableItem.delivered_quantity" min="0" inline
                                                size='sm'></b-form-spinbutton>
                                        </b-form-group>
                                        <b-form-group>
                                            <b-form-checkbox v-model="entitySaleableItem.all_delivered" value="1"
                                                unchecked-value="0">
                                                all delivered
                                            </b-form-checkbox>
                                        </b-form-group>
                                    </div>
                                    <div class='mt-3'>
                                    </div>
                                    <div class='mt-3'>
                                        <b-form-datepicker v-model="entitySaleableItem.start_date" today-button reset-button
                                            close-button v-on:input='updateEndDate(entitySaleableItem)'
                                            class="mb-2"></b-form-datepicker>
                                        <b-form-datepicker v-model="entitySaleableItem.end_date" today-button reset-button
                                            close-button class="mb-2"></b-form-datepicker>
                                    </div>
                                </div>

                                <div class='mt-3'>
                                    <b-form-textarea v-model='entitySaleable.note' size="sm" rows="1" no-resize
                                        placeholder="note goes here" maxlength='255' />
                                </div>

                            </b-col>
                        </b-row>
                    </b-col>
                    <b-col cols='7'>
                        <b-row align-v="center">
                            <b-col cols='3'>
                                <b-form-spinbutton v-model="entitySaleable.quantity" min="1" inline
                                    size='sm'></b-form-spinbutton>
                            </b-col>
                            <b-col cols='3' class='pl-4'>
                                x
                                <span v-if='entitySaleable.saleable.custom_price == 0'>
                                    {{ entitySaleable.price }}
                                </span>
                                <b-form-group v-else>
                                    <b-input v-model='entitySaleable.price' />
                                </b-form-group>
                            </b-col>
                            <b-col cols='3'>
                                <div>
                                    <b>discount %</b>
                                    <b-form-input v-model="entitySaleable.discount" type='number' step='0.5' min='0'
                                        max='100' />
                                </div>
                            </b-col>
                            <b-col cols='3'>
                                {{ (1 - (entitySaleable.discount ? entitySaleable.discount / 100 : 0)) *
                                    entitySaleable.price
                                    * entitySaleable.quantity | currency }}
                            </b-col>
                        </b-row>
                        <b-row class='mt-2'>

                            <b-col>

                            </b-col>
                            <b-col>
                                <b-form-group label='Payed amount'>
                                    <b-form-input v-if='entitySaleable.all_payed != 1' v-model="entitySaleable.amount_payed"
                                        type='number' step='0.5' min='0' max='100' />
                                </b-form-group>

                                <b-form-checkbox v-model="entitySaleable.all_payed" value="1" unchecked-value="0">
                                    all payed
                                </b-form-checkbox>
                            </b-col>
                        </b-row>
                    </b-col>

                </b-row>
                <div class='m-2'>
                    <h3>Total Required: {{ totalRequired }}</h3>
                    <h3>Total Payed: {{ totalPayed }}</h3>
                </div>
            </div>

            <button v-if='!this.saving' type="submit" class="btn btn-primary">Save</button>

            <b-button v-if='this.saving' variant="primary" :disabled='true'>
                <b-spinner small type="grow"></b-spinner>
                saving...
            </b-button>
        </b-form>

        <Modals :modalName='currentModal' :modalData='currentModalData' @saved='modalCallback' @closed='modalClosed'>
        </Modals>
    </div>
</template>

<script>
import BaseForm from '@/views/BaseForm'
import EntitiesSelect from '../../components/Select/EntitiesSelect.vue';
import moment from 'moment';

export default {
    components: { EntitiesSelect },
    extends: BaseForm,
    props: ['is_purchase', 'entityType', 'entityId'],
    data: function () {
        return {
            entityService: {
            },
            entitySaleables: [],
            entity: null,
            cashPaymentDate: this.today()
        }
    },
    mounted() {
        if (this.entityId) {
            this.apiRequest(apiClient => apiClient.get('entities/' + this.entityId))
                .then(response => {
                    this.entity = response;
                    this.modal('saleablesSelector', { multiple: true, filter: { entity_type: this.entity ? this.entity.type : this.entityType, is_active: 1 } }, 'addSaleables');
                });
        } else if (this.entityType) {
            var user = this.loadUser();
            if (typeof user.defaultEntities[this.entityType] !== 'undefined') {
                this.entity = user.defaultEntities[this.entityType].entity;
            }

        }
    },
    methods: {
        load: function (id) {
            return this.apiRequest(apiClient => apiClient.get('entity-saleables/' + id))
                .then(response => this.entityService = response);
        },
        addSaleables: function (saleables) {
            saleables.forEach(saleable => {
                var status = 'pending';
                var entitySaleableItems = saleable.saleable_items.map(saleableItem => ({
                    item_type: saleableItem.item_type,
                    item_id: saleableItem.item_id,
                    item: saleableItem.item,
                    start_date: this.getStartDate(saleableItem),
                    end_date: this.getEndDate(saleableItem),
                    quantity: saleableItem.quantity,
                    delivered_quantity: 0,
                    all_delivered: 0,
                    status: status,
                    period: saleableItem.period,
                }));
                this.entitySaleables.push({
                    saleable: saleable,
                    saleable_id: saleable.id,
                    quantity: 1,
                    price: saleable.price,
                    all_payed: 1,
                    status: status,
                    entity_saleable_items: entitySaleableItems,
                    is_purchase: this.is_purchase == 1 ? 1 : 0
                });
            });

        },
        getStartDate(saleableItem) {
            if (saleableItem.period == "monthly") {
                return moment().startOf('month').format('YYYY-MM-DD');
            }
        },
        getEndDate(saleableItem) {
            if (saleableItem.period == "monthly") {
                return moment().endOf('month').format('YYYY-MM-DD');
            }
        },
        save: function (apiClient) {
            let cashRegisterId = localStorage.cashRegisterId;
            return apiClient.post('entity-saleables/create-many', { entity_saleables: this.entitySaleables, entity_id: this.entity.id, cash_payment_date: this.cashPaymentDate, cash_register_id: cashRegisterId });
        },
        afterSave: function () {
            this.$emit('saved', 1);
        },
        removeEntitySaleable(index) {
            this.entitySaleables.splice(index, 1);
        },
        updateEndDate(entitySaleableItem) {
            entitySaleableItem.end_date = moment(entitySaleableItem.start_date).endOf('month').format('YYYY-MM-DD');
        },
    },
    computed: {
        totalRequired: function () {
            return this.entitySaleables.reduce((total, entitySaleable) => {
                return total + (1 - (entitySaleable.discount ? entitySaleable.discount / 100 : 0)) * entitySaleable.price * entitySaleable.quantity;
            }, 0);
        },
        totalPayed: function () {
            return this.entitySaleables.reduce((total, entitySaleable) => {
                var required = (1 - (entitySaleable.discount ? entitySaleable.discount / 100 : 0)) * entitySaleable.price * entitySaleable.quantity;
                return total + (entitySaleable.all_payed == 1 ? required : parseFloat(entitySaleable.amount_payed ? entitySaleable.amount_payed : 0));
            }, 0);
        },
    }
}
</script>