<template>
    <div>
        <loader v-if='loading'/>
        <b-form v-if='!loading' class='basic-form' @submit="submit" novalidate>
            <b-form-group label="Name">
                <b-form-input v-model="kid.name" :state="states.name" type="text" placeholder="">
                </b-form-input>
                <FormGroupError :errors='errors.name'/>
            </b-form-group>
            <b-form-group label="Nick Name">
                <b-form-input v-model="kid.nick_name" :state="states.name" type="text" placeholder="">
                </b-form-input>
                <FormGroupError :errors='errors.name'/>
            </b-form-group>
            <b-form-group label="Birth Date">
                <b-form-datepicker v-model="kid.birth_date"></b-form-datepicker>
                <FormGroupError :errors='errors.birth_date'/>
            </b-form-group>
            
            <b-form-group label="Gender">
                <b-form-radio-group
                    v-model="kid.gender"
                    :options="['male', 'female']"
                ></b-form-radio-group>
                <FormGroupError :errors='errors.gender'/>
            </b-form-group>

            <b-form-group label="Class Room" >
                <b-col cols='6'>
                    <class-rooms-select  v-model='kid.class_room_id' :returnId='true'/>
                </b-col>
                <FormGroupError :errors='errors.class_room_id'/>
            </b-form-group>
            <FormErrors :hasErrors='hasErrors' :errors='errors'></FormErrors>

            <entity-form-part v-model='kid.entity'/>
            
            <button v-if='!this.saving' type="submit" class="btn btn-primary">Save</button>

            <b-button v-if='this.saving' variant="primary" :disabled='true'>
                <b-spinner small type="grow"></b-spinner>
                saving...
            </b-button>
        </b-form>
    </div>
</template>

<script>
import BaseForm from '@/views/BaseForm'
import ClassRoomsSelect from '../../components/Select/ClassRoomsSelect.vue';
import EntityFormPart from '../../components/Forms/EntityFormPart.vue';
export default {
    components: { 
        ClassRoomsSelect, 
        EntityFormPart
    },
    extends: BaseForm,
    data: function () {
        return {
            kid: {
                entity: {
                    is_active: 1,
                    deliver_without_sale: 1,
                    entity_phones: [
                        {
                            name: 'Father',
                            number: ''
                        },
                        {
                            name: 'Mother',
                            number: ''
                        },
                    ]
                }
            }
        }
    },
    methods: {
        load: function(id) {
            return this.apiRequest(apiClient => apiClient.get('kids/'+id))
                .then(response => this.kid = response);
        },
        save: function (apiClient) {
            if (this.id) {
                return apiClient.put('kids/'+this.id, this.kid);
            } else {
                return apiClient.post('kids', this.kid);
            }
        },
        afterSave: function (kid) {
            this.$emit('saved', kid);
        }
    },
}
</script>