<template>
    <div>
        <loader v-if='loading'/>
        <b-form v-if='!loading' class='basic-form' @submit="submit" novalidate>
            <b-form-group label="Date">
                <b-form-datepicker v-model="date" :state="states.date"/>
                <FormGroupError :errors='errors.date'/>
            </b-form-group>
            <b-form-group label="Entity" v-if='!this.entityId'>
                <b-col cols='6'>
                    <entities-select  v-model='entity' :filter="entitiesFilter"/>
                </b-col>
                <FormGroupError :errors='errors.entitiy_id'/>
            </b-form-group>

            <b-row v-for='(entityItem, index) in entityItems' :key='index' class='m-2' align-v='center'>
                <b-col>
                    {{ entityItem.item.name }}
                </b-col>
                <b-col>
                    {{ entityItem.entity_saleable_item.entity_saleable.description }}
                    {{ entityItem.entity_saleable_item.status }}
                    {{ entityItem.entity_saleable_item.start_date }}
                    {{ entityItem.entity_saleable_item.end_date }}
                </b-col>
                <b-col>
                    <b-form-input type='number' v-model='entityItem.quantity' min='1' :max='entityItem.item.max_amount_per_day'/>
                </b-col>
            </b-row>
            
            <FormErrors :hasErrors='hasErrors' :errors='errors'></FormErrors>

            <button v-if='!this.saving' type="submit" class="btn btn-primary">Save</button>

            <b-button v-if='this.saving' variant="primary" :disabled='true'>
                <b-spinner small type="grow"></b-spinner>
                saving...
            </b-button>
        </b-form>
    </div>
</template>

<script>
import BaseForm from '@/views/BaseForm'
export default {
    extends: BaseForm,
    props: ['entityId'],
    data: function () {
        return {
            entityItems: [],
            date: this.today(),
            entity: null
        }
    },
    mounted() {
        this.apiRequest(apiClient => apiClient.get('entities/'+this.entityId))
                .then(response => this.entity = response);
    },
    methods: {
        load: function(id) {
            return this.apiRequest(apiClient => apiClient.get('entityItems/'+id))
                .then(response => this.entityItem = response);
        },
        save: function (apiClient) {
            if (this.id) {
                return apiClient.put('entityItems/'+this.id, this.entityItem);
            } else {
                return apiClient.post('entityItems', this.entityItem);
            }
        },
        afterSave: function () {
            this.$emit('saved', 1);
        },
        addEntitySaleableItems: function (entitySaleableItems) {
            entitySaleableItems.forEach(entitySaleableItem => {
                
                // var amount = transaction.amount_remaining;
                // var remainingAmount = parseFloat(this.cashPayment.amount) - this.totalCashPaymnetPartsAmount;
                // if (remainingAmount < transaction.amount_remaining) {
                //     amount = remainingAmount;
                // }
                this.entityItems.push({
                    entity_saleable_item: entitySaleableItem,
                    item: entitySaleableItem.item,
                    quantity: entitySaleableItem.remaining_quantity > entitySaleableItem.item.max_amount_per_day ? entitySaleableItem.item.max_amount_per_day : entitySaleableItem.remaining_quantity,
                });
            })
        }
    },
    watch: {
        entity: function () {
            if (this.id) {
                return;
            }

            this.entityItems = [];
            if (!this.entity) {
                return;
            }
            
            this.apiRequest(apiClient => apiClient.get('entity-saleable-items', {entity_id: this.entityId, not_consumed: 1}))
                .then(response => {
                    this.addEntitySaleableItems(response.data);
                });
        }
    },
}
</script>