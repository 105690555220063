<template>
    <div>
        <loader v-if='loading'/>
        <b-form v-if='!loading' class='basic-form' @submit="submit" novalidate>
            <b-form-group label="Name">
                <b-form-input v-model="priceGroup.name" :state="states.name" type="text" placeholder="">
                </b-form-input>
                <FormGroupError :errors='errors.name'/>
            </b-form-group>

            <b-form-group>
                <b-form-checkbox
                    v-model="priceGroup.is_active"
                    value="1"
                    unchecked-value="0"
                >
                    Active
                </b-form-checkbox>
            </b-form-group>
            <div class='btn btn-xs btn-primary m-2' @click="modal('saleablesSelector', {multiple: true, is_active: 1}, 'addSaleables')">Add Saleables</div>
           
            <div class='m-3' >
                 <b-row class='mb-4'>
                    <b-col cols='4'><b>Saleable</b></b-col>
                    <b-col cols='3'><b>Price</b></b-col>
                    <b-col cols='3'><b>Discount %</b></b-col>
                    <b-col cols=1></b-col>
                </b-row>
                <div v-if='saleablePrices.length == 0' class='m-5'>
                    <h4>
                    Select one or more saleable
                    </h4>
                </div>
                <b-row v-for='(saleablePrice, index) in saleablePrices' :key='index' class='mb-3' align-v="center">
                    <b-col cols='4'>
                        {{ saleablePrice.saleable.name }}

                        <div v-for='(saleableItem, index) in saleablePrice.saleable.saleable_items' :key='index'>
                            <div>
                                <div class='badge badge-info m-2'>
                                    {{ saleableItem.item.name }}
                                </div>
                                <div class='badge badge-info m-2'>
                                    period: {{ saleableItem.period }}
                                </div>
                                <div class='badge badge-info ml-2'>
                                    {{ saleableItem.quantity }} {{ saleableItem.quantity | pluralize('item', 'items') }}
                                </div>
                            </div>
                        </div>
                    </b-col>
                    <b-col cols='3'>
                        <b-form-input v-model="saleablePrice.price" type="number" placeholder=""/>
                    </b-col>
                    <b-col cols='3'>
                        <b-form-input v-model="saleablePrice.discount" type="number" placeholder=""/>
                    </b-col>

                    <b-col cols='1'>
                        <b-button variant='danger' class='btn btn-danger btn-xs sharp' @click='removeSaleablePrice(index)'>
                            <i class='fa fa-remove'></i>
                        </b-button>
                    </b-col>
                </b-row>
            </div>

            <FormErrors :hasErrors='hasErrors' :errors='errors'></FormErrors>

            <button v-if='!this.saving' type="submit" class="btn btn-primary">Save</button>

            <b-button v-if='this.saving' variant="primary" :disabled='true'>
                <b-spinner small type="grow"></b-spinner>
                saving...
            </b-button>
        </b-form>
		<Modals :modalName='currentModal' :modalData='currentModalData' @saved='modalCallback' @closed='modalClosed'>
		</Modals>
    </div>
</template>

<script>
import BaseForm from '@/views/BaseForm'
export default {
    extends: BaseForm,
    data: function () {
        return {
            priceGroup: {
            },
            saleablePrices: []
        }
    },
    methods: {
        load: function(id) {
            return this.apiRequest(apiClient => apiClient.get('price-groups/'+id))
                .then(response => {
                    this.priceGroup = response;
                    this.saleablePrices = response.saleable_prices;
                });
        },
        save: function (apiClient) {
            var priceGroup = {
                ...this.priceGroup,
                saleable_prices: this.saleablePrices.map(item => ({
                    saleable_id: item.saleable.id,
                    price: item.price,
                    discount: item.discount,
                }))
            }
            if (this.id) {
                return apiClient.put('price-groups/'+this.id, priceGroup);
            } else {
                return apiClient.post('price-groups', priceGroup);
            }
        },
        addSaleables: function (saleables) {
            saleables.forEach(saleable => {    
                
                this.saleablePrices.push({
                    saleable: saleable,
                    price: saleable.sale_price,
                    discount: 0
                });
            });
            
        },
        afterSave: function () {
            this.$emit('saved', 1);
        },
        removeSaleablePrice(index) {
            this.saleablePrices.splice(index, 1);
        }
    },
}
</script>