<template>
    <div>
        <loader v-if='loading'/>
        <b-form v-if='!loading' class='basic-form' @submit="submit" novalidate>
            <b-form-group label="Name">
                <b-form-input v-model="saleableGroup.name" :state="states.name" type="text" placeholder="">
                </b-form-input>
                <FormGroupError :errors='errors.name'/>
            </b-form-group>
            <div class='btn btn-xs btn-primary m-2' @click="modal('saleablesSelector', {multiple: true, filter: {entity_type: 'kid', is_active: 1}}, 'addSaleables')">Select Saleables</div>
            
            <div v-for='(saleable, index) in saleables' :key='index'>
                <div class='badge badge-light m-2'>
                {{ saleable.name }}
                </div>
            </div>
            <FormErrors :hasErrors='hasErrors' :errors='errors'></FormErrors>

            <button v-if='!this.saving' type="submit" class="btn btn-primary">Save</button>

            <b-button v-if='this.saving' variant="primary" :disabled='true'>
                <b-spinner small type="grow"></b-spinner>
                saving...
            </b-button>
        </b-form>

		<Modals :modalName='currentModal' :modalData='currentModalData' @saved='modalCallback' @closed='modalClosed'>
		</Modals>
    </div>
</template>

<script>
import BaseForm from '@/views/BaseForm'
export default {
    extends: BaseForm,
    data: function () {
        return {
            saleableGroup: {
            },
            saleables: []
        }
    },
    methods: {
        load: function(id) {
            return this.apiRequest(apiClient => apiClient.get('saleable-groups/'+id))
                .then(response => {
                    this.saleableGroup = response;
                    this.saleables = response.saleables;
                });
        },
        save: function (apiClient) {
            var saleableGroup = {
                name: this.saleableGroup.name,
                saleable_ids: this.saleables.map(item => item.id),
            };
            if (this.id) {
                return apiClient.put('saleable-groups/'+this.id, saleableGroup);
            } else {
                return apiClient.post('saleable-groups', saleableGroup);
            }
        },
        addSaleables: function (saleables) {
            saleables.forEach(saleable => {
                this.saleables.push(saleable);
            });
        },
        afterSave: function () {
            this.$emit('saved', 1);
        }
    },
}
</script>