<template>
    <div>
        <b-modal v-if='this.isCurrentModal("kidForm")' :no-close-on-backdrop=true size='xl' @close='closed()' hide-footer
            :visible="showModalView" title="Kid Form">
            <kid-form @saved='saved' v-bind='modalData'></kid-form>
        </b-modal>

        <b-modal v-if='this.isCurrentModal("entityForm")' :no-close-on-backdrop=true size='xl' @close='closed()' hide-footer
            :visible="showModalView" title="Entity Form">
            <entity-form @saved='saved' v-bind='modalData'></entity-form>
        </b-modal>

        <b-modal v-if='this.isCurrentModal("kidApplicationForm")' :no-close-on-backdrop=true size='xl' @close='closed()'
            hide-footer :visible="showModalView" title="Kid Application Form">
            <kid-application-form @saved='saved' v-bind='modalData'></kid-application-form>
        </b-modal>

        <b-modal v-if='this.isCurrentModal("classRoomForm")' :no-close-on-backdrop=true @close='closed()' hide-footer
            :visible="showModalView" title="Classroom Form">
            <class-room-form @saved='saved' v-bind='modalData'></class-room-form>
        </b-modal>

        <b-modal v-if='this.isCurrentModal("itemForm")' size='xl' :no-close-on-backdrop=true @close='closed()' hide-footer
            :visible="showModalView" title="Item Form">
            <item-form @saved='saved' v-bind='modalData'></item-form>
        </b-modal>

        <b-modal v-if='this.isCurrentModal("entitySaleablesForm")' size='xl' :no-close-on-backdrop=true @close='closed()'
            hide-footer :visible="showModalView" title="Entity Saleables Form">
            <entity-saleables-form @saved='saved' v-bind='modalData'></entity-saleables-form>
        </b-modal>

        <b-modal v-if='this.isCurrentModal("entitySaleableForm")' size='xl' :no-close-on-backdrop=true @close='closed()'
            hide-footer :visible="showModalView" title="Entity Saleable Form">
            <entity-saleable-form @saved='saved' v-bind='modalData'></entity-saleable-form>
        </b-modal>

        <b-modal v-if='this.isCurrentModal("itemsSelector")' size='xl' :no-close-on-backdrop=true @close='closed()'
            hide-footer :visible="showModalView" title="Items Selector">
            <items-selector @saved='saved' v-bind='modalData'></items-selector>
        </b-modal>

        <b-modal v-if='this.isCurrentModal("saleablesSelector")' size='xl' :no-close-on-backdrop=true @close='closed()'
            hide-footer :visible="showModalView" title="Saleables Selector">
            <saleables-selector @saved='saved' v-bind='modalData'></saleables-selector>
        </b-modal>

        <b-modal v-if='this.isCurrentModal("transactionsSelector")' size='xl' :no-close-on-backdrop=true @close='closed()'
            hide-footer :visible="showModalView" title="Transcactions Selector">
            <transactions-selector @saved='saved' v-bind='modalData'></transactions-selector>
        </b-modal>

        <b-modal v-if='this.isCurrentModal("cashPaymentPartsSelector")' size='xl' :no-close-on-backdrop=true
            @close='closed()' hide-footer :visible="showModalView" title="Cash Payment Parts Selector">
            <cash-payment-parts-selector @saved='saved' v-bind='modalData'></cash-payment-parts-selector>
        </b-modal>

        <b-modal v-if='this.isCurrentModal("cashPaymentForm")' size='xl' :no-close-on-backdrop=true @close='closed()'
            hide-footer :visible="showModalView" title="Cash Payment Form">
            <cash-payment-form @saved='saved' v-bind='modalData'></cash-payment-form>
        </b-modal>

        <b-modal v-if='this.isCurrentModal("cashReturnForm")' size='xl' :no-close-on-backdrop=true @close='closed()'
            hide-footer :visible="showModalView" title="Cash Return Form">
            <cash-return-form @saved='saved' v-bind='modalData'></cash-return-form>
        </b-modal>

        <b-modal v-if='this.isCurrentModal("multipleEntityItemForm")' size='xl' :no-close-on-backdrop=true @close='closed()'
            hide-footer :visible="showModalView" title="Entity Items Form">
            <multiple-entity-item-form @saved='saved' v-bind='modalData'></multiple-entity-item-form>
        </b-modal>


        <b-modal v-if='this.isCurrentModal("entityItemsForm")' size='xl' :no-close-on-backdrop=true @close='closed()'
            hide-footer :visible="showModalView" title="Entity Items Form">
            <entity-items-form @saved='saved' v-bind='modalData'></entity-items-form>
        </b-modal>



        <b-modal v-if='this.isCurrentModal("itemsDeliveryForm")' size='xl' :no-close-on-backdrop=true @close='closed()'
            hide-footer :visible="showModalView" title="Items Delivery Form">
            <items-delivery-form @saved='saved' v-bind='modalData'></items-delivery-form>
        </b-modal>

        <b-modal v-if='this.isCurrentModal("priceGroupForm")' size='xl' :no-close-on-backdrop=true @close='closed()'
            hide-footer :visible="showModalView" title="Price Group Form">
            <price-group-form @saved='saved' v-bind='modalData'></price-group-form>
        </b-modal>
        <b-modal v-if='this.isCurrentModal("saleableForm")' size='xl' :no-close-on-backdrop=true @close='closed()'
            hide-footer :visible="showModalView" title="Saleable Form">
            <saleable-form @saved='saved' v-bind='modalData'></saleable-form>
        </b-modal>

        <b-modal v-if='this.isCurrentModal("entityItemsList")' size='xl' :no-close-on-backdrop=true @close='closed()'
            hide-footer :visible="showModalView" title="Entity Items List">
            <entity-items-list-table v-bind='modalData'></entity-items-list-table>
        </b-modal>

        <b-modal v-if='this.isCurrentModal("cashPaymentsList")' size='xl' :no-close-on-backdrop=true @close='closed()'
            hide-footer :visible="showModalView" title="Cash Payments List">
            <cash-payments-list-table v-bind='modalData'></cash-payments-list-table>
        </b-modal>


        <b-modal v-if='this.isCurrentModal("entitySaleablesList")' size='xl' :no-close-on-backdrop=true @close='closed()'
            hide-footer :visible="showModalView" title="Sales List">
            <entity-saleables-list-table v-bind='modalData'></entity-saleables-list-table>
        </b-modal>

        <b-modal v-if='this.isCurrentModal("summaryView")' size='xl' :no-close-on-backdrop=true @close='closed()'
            hide-footer :visible="showModalView" title="Summary">
            <summary-view v-bind='modalData'></summary-view>
        </b-modal>

        <b-modal v-if='this.isCurrentModal("entityNoteForm")' size='xl' :no-close-on-backdrop=true @close='closed()'
            hide-footer :visible="showModalView" title="Note Form">
            <entity-note-form @saved='saved' v-bind='modalData'></entity-note-form>
        </b-modal>
        <b-modal v-if='this.isCurrentModal("saleableGroupForm")' size='xl' :no-close-on-backdrop=true @close='closed()'
            hide-footer :visible="showModalView" title="Saleable Group Form">
            <saleable-group-form @saved='saved' v-bind='modalData'></saleable-group-form>
        </b-modal>
        <b-modal v-if='this.isCurrentModal("deleteCashPaymentForm")' size='xl' :no-close-on-backdrop=true @close='closed()'
            hide-footer :visible="showModalView" title="Delete CashPayment">
            <delete-cash-payment-form @saved='saved' v-bind='modalData'></delete-cash-payment-form>
        </b-modal>
        <b-modal v-if='this.isCurrentModal("deleteEntityForm")' size='xl' :no-close-on-backdrop=true @close='closed()'
            hide-footer :visible="showModalView" title="Delete Entity">
            <delete-entity-form @saved='saved' v-bind='modalData'></delete-entity-form>
        </b-modal>
        <b-modal v-if='this.isCurrentModal("deleteEntitySaleableForm")' size='xl' :no-close-on-backdrop=true
            @close='closed()' hide-footer :visible="showModalView" title="Delete Entity Saleable">
            <delete-entity-saleable-form @saved='saved' v-bind='modalData'></delete-entity-saleable-form>
        </b-modal>

        <b-modal v-if='this.isCurrentModal("editClientSettings")' size='xl' :no-close-on-backdrop=true
            @close='closed()' hide-footer :visible="showModalView" title="Edit Client Settings">
            <client-settings-form @saved='saved' v-bind='modalData'></client-settings-form>
        </b-modal>
        
    </div>
</template>

<script>

import BaseModal from '@/components/Modals/BaseModal';
import ClassRoomForm from '@/views/ClassRooms/ClassRoomForm';
import ItemForm from '../../views/Items/ItemForm.vue';
import KidForm from '../../views/Kids/KidForm.vue';
import EntityForm from '../../views/Entities/EntityForm.vue';
import EntitySaleablesForm from '../../views/EntitySaleables/EntitySaleablesForm.vue';
import EntitySaleableForm from '../../views/EntitySaleables/EntitySaleableForm.vue';
import SaleablesSelector from '../Selectors/SaleablesSelector.vue';
import CashPaymentForm from '../../views/CashPayments/CashPaymentForm.vue';
import TransactionsSelector from '../Selectors/TransactionsSelector.vue';
import MultipleEntityItemForm from '../../views/EntityItems/MultipleEntityItemForm.vue';
import PriceGroupForm from '../../views/PriceGroups/PriceGroupForm.vue';
import SaleableForm from '../../views/Saleables/SaleableForm.vue';
import ItemsSelector from '../Selectors/ItemsSelector.vue';
import ItemsDeliveryForm from '../../views/EntityItems/ItemsDeliveryForm.vue';
import EntityItemsListTable from '../Lists/EntityItemsListTable.vue';
import CashPaymentsListTable from '../Lists/CashPaymentsListTable.vue';
import CashReturnForm from '../../views/CashPayments/CashReturnForm.vue';
import CashPaymentPartsSelector from '../Selectors/cashPaymentPartsSelector.vue';
import EntityItemsForm from '../../views/EntityItems/EntityItemsForm.vue';
import SummaryView from '../../views/Summary/SummaryView.vue';
import EntitySaleablesListTable from '../Lists/EntitySaleablesListTable.vue';
import KidApplicationForm from '../../views/Entities/KidApplicationForm.vue';
import EntityNoteForm from '../../views/EntityNotes/EntityNoteForm.vue';
import SaleableGroupForm from '../../views/SaleableGroups/SaleableGroupForm.vue';
import DeleteCashPaymentForm from '../../views/CashPayments/DeleteCashPaymentForm.vue';
import DeleteEntityForm from '../../views/Entities/DeleteEntityForm.vue';
import DeleteEntitySaleableForm from '../../views/EntitySaleables/DeleteEntitySaleableForm.vue';
import ClientSettingsForm from '../../views/ClientSettings/ClientSettingsForm.vue';

export default {
    extends: BaseModal,
    components: {
        ClassRoomForm,
        ItemForm,
        KidForm,
        SaleablesSelector,
        EntitySaleablesForm,
        EntitySaleableForm,
        CashPaymentForm,
        TransactionsSelector,
        MultipleEntityItemForm,
        PriceGroupForm,
        SaleableForm,
        ItemsSelector,
        ItemsDeliveryForm,
        EntityItemsListTable,
        CashPaymentsListTable,
        EntityForm,
        CashReturnForm,
        CashPaymentPartsSelector,
        EntityItemsForm,
        SummaryView,
        EntitySaleablesListTable,
        KidApplicationForm,
        EntityNoteForm,
        SaleableGroupForm,
        DeleteCashPaymentForm,
        DeleteEntityForm,
        DeleteEntitySaleableForm,
        ClientSettingsForm
    },
    props: ['modalName', 'modalData'],
    methods: {
        isCurrentModal(modalName) {
            return this.modalName == modalName;
        }
    },
    computed: {
        showModalView() {
            return this.modalName ? true : false;
        }
    }
}
</script>