<template>
    <div>
        <loader v-if='loading'/>
        <b-form v-if='!loading' class='basic-form' @submit="submit" novalidate>
            <b-form-group label="Entity"  v-if='!this.entityId'>
                <b-col cols='6'>
                    <entities-select  v-model='entity' :filter="entitiesFilter"/>
                </b-col>
                <FormGroupError :errors='errors.entitiy_id'/>
            </b-form-group>

            <b-form-group label="Date" >
                <b-form-datepicker v-model="cashPayment.date" ></b-form-datepicker>
            </b-form-group>

            <b-form-group label="Payment Method">
                <b-col cols='6'>
                    <payment-methods-select v-model='cashPayment.payment_method_id' :returnId="true" />
                </b-col>
                <FormGroupError :errors='errors.payment_method_id' />
            </b-form-group>

            <b-row v-for='(cashPaymentPart, index) in cashPaymentParts' :key='index' class='m-2' align-v='center'>
                <b-col cols='6'>
                    <b-row align-v="center">
                        <b-col cols='1'>
                            <b-button variant='danger' class='btn btn-danger btn-xs sharp' @click='removeCashPaymentPart(index)'>
                                <i class='fa fa-remove'></i>
                            </b-button>
                        </b-col>
                        <b-col>
                            <div v-if='cashPaymentPart.transaction'>
                                {{ cashPaymentPart.transaction.title }}
                            </div>
                            <div v-else>
                                Down payment
                            </div>

                            <div class='badge badge-light'>{{ cashPaymentPart.cash_payment.date }}</div>
                        </b-col>
                        <b-col>
                            <div v-if='cashPaymentPart.transaction'>
                                <div v-if='cashPaymentPart.transaction.amount_remaining > 0'>
                                Remaining: {{ cashPaymentPart.transaction.amount_remaining | currency }}
                                </div>
                                <div class='badge' :class=" cashPaymentPart.transaction.amount_paid != cashPaymentPart.transaction.amount ? 'badge-danger' :  'badge-success'">
                                    {{ cashPaymentPart.transaction.amount_paid | currency }} / {{ cashPaymentPart.transaction.amount | currency }}
                                </div>           
                            </div>           
                        </b-col>
                    </b-row>
                </b-col>
                <b-col cols='2'>
                    <div v-if='cashPaymentPart.transaction' class='badge' :class='statusClass[cashPaymentPart.transaction.status]'>
                    {{ cashPaymentPart.transaction.status }}
                    </div>
                </b-col>
                <b-col>
                    <b-form-input v-model='cashPaymentPart.amount' type='number' min='0'/>
                </b-col>
            </b-row>

            <b-row  v-if='entity' align-v='center' class='m-2'>
                <b-col class='text-right p-3' cols='8'>
                    <b>
                    Total Required
                    </b>
                </b-col>
                <b-col>        
                    {{ totalCashPaymnetPartsAmount }}
                </b-col>
            </b-row>
            <div class='btn btn-xs btn-primary mb-4' v-if='entity' @click="modal('cashPaymentPartsSelector', {multiple: true, filter:{entity_id: entity.id, pending: 1, not_in_ids: cashPaymentPartsIds}}, 'addCashPaymentParts')">Add</div>
            
            <b-form-group label="Total Payment Amount" >
                <b-form-input v-model='cashPayment.amount' type='number' min='0'/>
            </b-form-group>
            <h3>
                <div class='badge badge-light'>
                    Remaining Amount:
                {{ cashPayment.amount - totalCashPaymnetPartsAmount | currency}}
                </div>
            </h3>
            

            <FormErrors :hasErrors='hasErrors' :errors='errors'></FormErrors>

            <button v-if='!this.saving' type="submit" class="btn btn-primary">Save</button>

            <b-button v-if='this.saving' variant="primary" :disabled='true'>
                <b-spinner small type="grow"></b-spinner>
                saving...
            </b-button>
        </b-form>

		<Modals :modalName='currentModal' :modalData='currentModalData' @saved='modalCallback' @closed='modalClosed'>
		</Modals>
    </div>
</template>

<script>
import BaseForm from '@/views/BaseForm'
import EntitiesSelect from '../../components/Select/EntitiesSelect.vue';
import PaymentMethodsSelect from '../../components/PaymentMethodsSelect.vue';

export default {
  components: { EntitiesSelect, PaymentMethodsSelect },
    extends: BaseForm,
    props: ['type', 'entityId'],
    data: function () {
        return {
            cashPayment: {
                amount: 0,
                date: this.today()
            },
            remainingTransactionsCount: 0,
            cashPaymentParts: [],
            entity: null,
            downPaymentAmount: 0,
            statusClass: {
                'in progress': 'badge-warning',
                'finished': 'badge-success',
                'pending': 'badge-light',
            },
            entitiesFilter: {}
        }
    },
    mounted() {
        
        if (!this.entityId) {
            var types = [];
            if (this.type == 'cash-out') {
                types = ['customer', 'kid'];
            } else if (this.type == 'cash-in') {
                types = ['supplier'];
            }
            
            this.entitiesFilter = {
                types: types
            };
        } else {
            this.apiRequest(apiClient => apiClient.get('entities/'+this.entityId))
                .then(response => this.entity = response);
        }
    },
    methods: {
        load: function(id) {
            return this.apiRequest(apiClient => apiClient.get('cash-payments/'+id))
                .then(response => {
                    this.cashPayment = response;
                    this.cashPaymentParts = response.cash_return_payment_parts;
                    this.entity = response.entity;
                });
        },
        save: function (apiClient) {
            let cashRegisterId = localStorage.cashRegisterId;

            var cashReturnPayment = {
                cash_register_id: cashRegisterId,
                entity_id: this.entity.id,
                payment_method_id: this.cashPayment.payment_method_id,
                date: this.cashPayment.date,
                amount: this.cashPayment.amount,
                type: this.type,
                is_return: 1,
                cash_payment_parts: this.cashPaymentParts.map(
                        cashPaymentPart => ({
                            id: cashPaymentPart.id,
                            amount: cashPaymentPart.amount
                        })
                    )
            }
            if (this.id) {
                return apiClient.put('cash-payments/'+this.id, cashReturnPayment);
            } else {
                return apiClient.post('cash-payments', cashReturnPayment);
            }
        },
        afterSave: function () {
            this.$emit('saved', 1);
        },
        addCashPaymentParts: function (cashPaymentParts) {
            this.cashPaymentParts = [...this.cashPaymentParts, ...cashPaymentParts];
        },
        removeCashPaymentPart(index) {
            this.cashPaymentParts.splice(index, 1);
        }
    },
    watch: {
        entity: function () {
            if (this.id) {
                return;
            }

            this.cashPaymentParts = [];
            if (!this.entity) {
                return;
            }
            var filter = {entity_id: this.entity.id, pending: 1};
            this.apiRequest(apiClient => apiClient.get('cash-payment-parts', filter))
                .then(response => {
                    this.addCashPaymentParts(response.data);
                });
        }
    },
    computed: {
        totalCashPaymnetPartsAmount: function () {
            return this.cashPaymentParts.reduce((total, item) => {
                return total + parseFloat(item.amount)
            }, 0);
        },
        cashPaymentPartsIds: function () {
            return this.cashPaymentParts.map(item => item.id);
        },
        totalPaymentAmount: function () {
            return parseFloat(this.cashPayment.downpayment_amount??0) + parseFloat(this.totalCashPaymnetPartsAmount);
        }
    }
}
</script>