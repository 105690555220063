<template>
    <div>
        <loader v-if='loading'/>
        <b-form v-else class='basic-form' @submit="submit" novalidate>
            <b-form-group label="Saleable">
                <b>
                    {{ entitySaleable.description }}
                </b>

                <div v-for='(entitySaleableItem, index) in entitySaleable.entity_saleable_items' :key='index'>

                    <div>
                        <div class='badge badge-info m-2'>
                            {{ entitySaleableItem.item.name }}
                        </div>

                        <div class='badge badge-info ml-2'>
                            {{ entitySaleableItem.quantity }} {{ entitySaleableItem.quantity | pluralize('item', 'items') }}
                        </div>
                    </div>
                    <div class='mt-3'>
                        <b-form-radio-group v-model='entitySaleableItem.status' :options='["pending", "in progress", "finished"]'/>
                    </div>
                    <div class='mt-3'>
                        <b-form-datepicker v-model="entitySaleableItem.start_date" v-on:input='updateEndDate(entitySaleableItem)' 
                            today-button
                            reset-button
                            close-button
                            class="mb-2"></b-form-datepicker>
                        <b-form-datepicker v-model="entitySaleableItem.end_date" 
                            today-button
                            reset-button
                            close-button
                            class="mb-2"></b-form-datepicker>
                    </div>
                </div>
            </b-form-group>


            <b-form-group label="Saleable" v-if='entitySaleable.entity_saleable_items.length == 0'>
                <b-form-datepicker v-model="entitySaleable.started_at" v-on:input='updateSaleableEndDate(entitySaleable)' 
                        today-button
                        reset-button
                        close-button class="mb-2"></b-form-datepicker>
                <b-form-datepicker v-model="entitySaleable.finished_at" 
                        today-button
                        reset-button
                        close-button class="mb-2"></b-form-datepicker>
            </b-form-group>
            

            <b-form-group label="Note">
                <b-form-textarea v-model='entitySaleable.note' size="sm" rows="1" no-resize placeholder="note goes here" maxlength='255'/> 
            </b-form-group>
            
            <b-form-group label='price'>
                <b v-if='entitySaleable.saleable.custom_price == 0'>
                {{ entitySaleable.price }}
                </b>
                <b-form-input v-else v-model="entitySaleable.price" type='number'/>
            </b-form-group>
            <b-form-group label="Quantity">
                <b-form-spinbutton v-model="entitySaleable.quantity" min="1" inline size='sm'></b-form-spinbutton>
            </b-form-group>

            <b-form-group label='discount'>
                <b-form-input v-model="entitySaleable.discount" type='number' step='0.5' min='0' max='100'/>
            </b-form-group>

            <b-form-group label='final price'>
                <b>
                {{ (1 - (entitySaleable.discount ? entitySaleable.discount/100 : 0)) * entitySaleable.price * entitySaleable.quantity | currency }}
                </b>
            </b-form-group>
            
            <button v-if='!this.saving' type="submit" class="btn btn-primary">Save</button>

            <b-button v-if='this.saving' variant="primary" :disabled='true'>
                <b-spinner small type="grow"></b-spinner>
                saving...
            </b-button>
        </b-form>

    </div>
</template>

<script>
import BaseForm from '@/views/BaseForm'
import moment from 'moment';

export default {
  components: {  },
    extends: BaseForm,
    data: function () {
        return {
            entitySaleable: {},
        }
    },
    methods: {
        load: function(id) {
            return this.apiRequest(apiClient => apiClient.get('entity-saleables/'+id))
                .then(response => this.entitySaleable = response);
        },
        save: function (apiClient) {
            return apiClient.put('entity-saleables/'+this.id, this.entitySaleable);
        },
        afterSave: function () {
            this.$emit('saved', 1);
        },
        updateEndDate(entitySaleableItem) {
            entitySaleableItem.end_date = moment(entitySaleableItem.start_date).endOf('month').format('YYYY-MM-DD');
        }
    },
}
</script>