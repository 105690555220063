<template>
    <div>
        <loader v-if='loading'/>
        <b-form v-if='!loading' class='basic-form' @submit="submit" novalidate>
            <b-form-group label="Date">
                <b-form-datepicker v-model="date" :state="states.date"/>
                <FormGroupError :errors='errors.date'/>
            </b-form-group>
            
            <b-form-group label="Item">
                <items-select v-model="items" multiple/>
            </b-form-group>
            
            <div v-for="(entityItem, index) in entityItems" :key='index'>
                <b-row class='m-2'>
                    <b-col>
                        <b>
                            {{ entityItem.entity.name }}
                        </b>
                    </b-col>
                    <b-col cols=8>
                        <div class='badge badge-success'>
                            {{ entityItem.item.name }}
                        </div>
                        <div class='badge badge-light ml-2'>
                            {{ entityItem.entity_saleable_item.entity_saleable.quantity }}
                            X
                            {{ entityItem.entity_saleable_item.entity_saleable.description }}
                        </div>

                        <div class='badge' :class="statusBadge(entityItem.entity_saleable_item.status)">
                            {{ entityItem.entity_saleable_item.status }}
                        </div>
                        <div class='badge badge ml-2'  :class="statusBadge(entityItem.entity_saleable_item.status)" title='used/remaining'>

                            {{ entityItem.entity_saleable_item.used_quantity }}
                            /
                            {{ entityItem.entity_saleable_item.total_quantity }}
                        </div>

                        <div v-if='entityItem.entity_saleable_item.start_date' class="badge badge-success ml-2">
                            from
                            <i class='fa fa-calendar'></i>
                            {{ entityItem.entity_saleable_item.start_date }}
                        </div>
                        <div v-if='entityItem.entity_saleable_item.end_date' class="badge badge-success ml-2">
                            to
                            <i class='fa fa-calendar'></i>
                            {{ entityItem.entity_saleable_item.end_date }}
                        </div>
                    </b-col>
                    <b-col>
                        <div v-if='entityItem.max_quantity > 0'>
                        <b-form-spinbutton v-model="entityItem.quantity" min="0" :max='entityItem.max_quantity' inline size='sm'></b-form-spinbutton>
                        </div>
                    </b-col>
                </b-row>
            </div>

            <FormErrors :hasErrors='hasErrors' :errors='errors'></FormErrors>

            <button v-if='!this.saving' type="submit" class="btn btn-primary">Save</button>

            <b-button v-if='this.saving' variant="primary" :disabled='true'>
                <b-spinner small type="grow"></b-spinner>
                saving...
            </b-button>
        </b-form>
    </div>
</template>

<script>
import BaseForm from '@/views/BaseForm'
import ItemsSelect from '../../components/Select/ItemsSelect.vue';
export default {
  components: { ItemsSelect },
    extends: BaseForm,
    data: function () {
        return {
            items: [],
            date: this.today(),
            entityItems: [],
            statusBadges: {
                "in progress": "badge-warning",
                "finished": "badge-success",
                "pending": "badge-light",
            }
        }
    },
    mounted: function () {
        this.loadEntities();
    },
    methods: {
        load: function(id) {
            return this.apiRequest(apiClient => apiClient.get('entityItems/'+id))
                .then(response => this.entityItem = response);
        },
        save: function (apiClient) {
            if (this.id) {
                return apiClient.put('entity-items/'+this.id, this.entityItem);
            } else {
                var entityItems = this.entityItems.map(item => ({
                    item_type: item.item_type,
                    item_id: item.item_id,
                    entity_id: item.entity.id,
                    entity_saleable_item_id: item.entity_saleable_item.id,
                    quantity: item.quantity,
                }));
                return apiClient.post('entity-items/create-many', {date: this.date, entity_items: entityItems});
            }
        },
        statusBadge: function(status) {
            return this.statusBadges[status] ? this.statusBadges[status] : "badge-light";
        },
        afterSave: function () {
            this.$emit('saved', 1);
        },
        loadEntities: function () {
            this.entityItems = [];
            
            this.apiRequest(
                apiClient => apiClient.get('entity-saleable-items', {hide_consumed: 1, date: this.date, item_ids: this.items.map(item => item.id)})
                    .then(
                        response =>
                            this.entityItems = response.data.map(
                                saleableItem => ({
                                    item: saleableItem.item,
                                    item_type: saleableItem.item_type,
                                    item_id: saleableItem.item_id,
                                    entity: saleableItem.entity_saleable.entity,
                                    quantity: saleableItem.entity_items.length > 0 ? parseInt(saleableItem.entity_items[0].quantity) : 0,
                                    entity_saleable_item: saleableItem,
                                    max_quantity: saleableItem.remaining_quantity + (saleableItem.entity_items.length > 0 ? 
                                        parseInt(saleableItem.entity_items[0].quantity) : 0)
                                })
                            )
                    )
            )
        }
    },

    watch: {
        items: function () {
            this.loadEntities();
        },
        date: function () {
            this.loadEntities();
        },
        hideConsumed: function () {
            this.loadEntities();
        }
    }
}
</script>